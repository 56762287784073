import React, { useContext, useEffect, useState } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EventListPageContext } from '../../containers/EventListPage/EventListPage';
import Icon from '../Icon';
import styles from './EventSelectedCategories.module.scss';

const EventSelectedCategories = () => {
    const [categories, setCategories] = useState([]);
    const { state, dispatch } = useContext(EventListPageContext);
    const classes = classNames(styles['EventSelectedCategories'], {
        [styles['EventSelectedCategories--HasItems']]:
            state.selectedCategories.length > 0,
    });

    const slugToTitle = (slug) => {
        return state.categories.find((category) => category.slug === slug)
            ?.title;
    };

    useEffect(() => {
        if (
            state.selectedCategories.length === 0 &&
            categories.length === 1
        ) {
            return;
        }
        setCategories(state.selectedCategories);
    }, [state.selectedCategories]);

    return (
        <div className={classes}>
            <ul
                className={classNames(
                    styles['EventSelectedCategories__Categories']
                )}>
                {categories.map((category) => {
                    if (!slugToTitle(category)) {
                        return;
                    }
                    return (
                        <li
                            className={classNames(
                                styles['EventSelectedCategories__Category']
                            )}>
                            <button
                                className={
                                    styles[
                                        'EventSelectedCategories__CategoryButton'
                                    ]
                                }
                                key={category.slug}
                                onClick={() => {
                                    dispatch({
                                        type: 'CATEGORY_SELECTED',
                                        excludeCategory: category,
                                    });
                                }}>
                                {slugToTitle(category)}
                                <Icon
                                    type="closebold"
                                    color={'black800'}
                                    size="small"
                                />
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

EventSelectedCategories.propTypes = {};

EventSelectedCategories.defaultProps = {};

export default EventSelectedCategories;
